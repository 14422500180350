import '../sass/main.scss';

import $ from 'jquery';
window.$ = window.jQuery = $;
import 'slick-carousel'; 
import LazyLoad from 'vanilla-lazyload';


var siteGlobal = {
  initReady: function () {
  },
  initLoad: function () {
    this.lazyLoad();
    this.menuInit();
    this.mobileMenuInit();
    this.homeVisual();
    this.homeNews();
    this.prodSmoothScroll();
    this.prodToggle();
    this.prodGalInit();
    this.sheetSlider();
    this.prodVideoPlay();
    this.stickyEl();
    this.scrollToTop();
    this.storiaGal();
    this.storiaToggle();
    this.popupInit();
    this.detectScrollDir();
    // this.filterExpand();
    this.rangeVal();
    this.mobileFiltersInit();
  },
  initScroll: function(){
    this.headerClass();
    this.stickyEl();
  },
  resize: function(){
  },
  lazyLoad: function(){
    var ll = new LazyLoad({
        elements_selector: '.lazy',
        use_native: true,
        threshold: 0
    })
  },
  headerClass: function(){
    if ($(window).scrollTop() > 20) {
      $('.header, #main_cont').addClass('scrolled');
    } else {
      $('.header, #main_cont').removeClass('scrolled');
    }
  },
  menuInit: function(){
    $('.header__hamburger').on('click', function(){
      $('.header__hamburger, .header, .mobNav').toggleClass('opened');
      $('.mobNav').fadeToggle(250);
    })

    $('.gam__trig').on('click', function(e){
      e.preventDefault();
      var trig = $(this).data('link');
      $('.gam__wrap').removeClass('active');

      if ($(this).hasClass('active')) {
        $('.header__menuWrapper').removeClass('opened');
        $(this).removeClass('active');
      } else {
        $('.gam__trig').removeClass('active');
        $(this).addClass('active');
        $('.header__menuWrapper').addClass('opened');
        $('.gam__wrap[data-trig="'+trig+'"]').addClass('active');
      }
    })
  },
  mobileMenuInit: function(){
    $('.mobNav__gTit').on('click', function(){
      $(this).toggleClass('active');
      var x = $(this).find('.plus');
      if (x[0].innerHTML === "+") {
        x[0].innerHTML = "-";
      } else {
        x[0].innerHTML = "+";
      }

      $(this).siblings('.mobNav__sub').slideToggle(250);
    })

    $('.mobNav__subName').on('click', function(){
      $(this).toggleClass('active');
      $(this).siblings('.mobNav__prodList').slideToggle(250);
    })
  },
  langsMenu: function(){
    $('.header__langs').on('click', function(){
      $(this).find('.arrow').toggleClass('rot');
      $(this).find('.lang-sub-menu').fadeToggle(150);
    })
  },
  detectScrollDir: function(){
    var lastScrollTop = 0;
    document.addEventListener("scroll", function(){
      var st = window.pageYOffset || document.documentElement.scrollTop;
      if (st > lastScrollTop){
        $('.header').removeClass('up');
      } else {
        $('.header').addClass('up');
      }
      lastScrollTop = st <= 0 ? 0 : st;
    }, false);
  },
  homeVisual: function(){
    if ($('.homeVisual').length) { 
      $('.homeVisual').not('.slick-initialized').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        fade:true,
        arrows: true,
        dots: true,
        autoplay: true,
        autoplaySpeed: 4500,
        lazyLoad: 'ondemand',
        speed: 1200,
        infinite: true
      });
    }
  },
  homeNews: function(){
    if ($('.homeNews__slider').length) { 
      $('.homeNews__slider').not('.slick-initialized').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        autoplay: true,
        autoplaySpeed: 4500,
        lazyLoad: 'progressive',
        speed: 600,
        infinite: true,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1
            }
          },{
            breakpoint: 991,
            settings: {
              slidesToShow: 2
            }
          }
        ]
      });
    }
  },
  prodToggle: function(){
    $('.detMac__tit').on('click', function(){
      $(this).find('.detMac__plus').toggleClass('act');
      $(this).siblings('.detMac__text').slideToggle(200);
    })
  },
  prodSmoothScroll: function(){
    $('.prodIntro__el, .prodIntro__btn').on('click', function(){
      event.preventDefault();
      var trig = $(this).data('sec-trig');
      $('html, body').animate({
        scrollTop: $('#'+trig).offset().top - 180
      }, 500);
    })
  },
  prodGalInit: function(){
    if ($('.ph__gal').length) { 
      $('.ph__gal').not('.slick-initialized').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        autoplay: true,
        autoplaySpeed: 4500,
        lazyLoad: 'ondemand',
        speed: 600,
        infinite: true,
        asNavFor: '.ph__thumbs'
      });
    }
    $('.ph__thumbs').slick({
      slidesToShow: 10,
      slidesToScroll: 1,
      asNavFor: '.ph__gal',
      dots: false,
      centerMode: false,
      focusOnSelect: true
    })
  },
  sheetSlider: function(){
    if ($('.sheetIntro__gal').length) { 
      $('.sheetIntro__gal').not('.slick-initialized').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        autoplay: true,
        autoplaySpeed: 4500,
        lazyLoad: 'ondemand',
        speed: 600,
        infinite: true,
      });
    }    
  },
  prodVideoPlay: function(){
    var vid = document.getElementById("vid");
    $('.ph__playWrap').on('click', function(){ 
      if (vid.paused) {
        vid.play();
        $('.ph__videoWrap').removeClass('paused');
      }
      else { 
        vid.pause(); 
        $('.ph__videoWrap').addClass('paused');
      } 
    })
  },
  stickyEl: function(){
    if ($(window).outerWidth(true) > 991 && $('#vers').length) { 
      var anchor = $("#vers"),
          scroller = $('.sheetIntro__col--gal'),
          st = $(window).scrollTop(),
          // ot = anchor.offset().top - ($(window).outerHeight(true) / 2) - 300;
          ot = anchor.offset().top - ($(window).outerHeight(true) / 2) - 350;

      if(st > ot) {
          scroller.css({
              position: "absolute",
              top: ot + 160
          });
      } else {
          scroller.css({
              position: "fixed",
              top: ""
          });
      }
    }
  },
  scrollToTop: function(){
    $('#totop').on('click', function (event) {
        event.preventDefault();
        $('html, body').animate({
          scrollTop: 0
        }, 500);
    });
  },
  storiaGal: function(){
    if ($('.vision__gall').length) { 
      $('.vision__gall').not('.slick-initialized').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        autoplay: true,
        autoplaySpeed: 4500,
        lazyLoad: 'ondemand',
        speed: 600,
        infinite: true,
      });
    }    
  },
  storiaToggle: function(){
    $('.storiaPage__trig').on('click', function(){
      $(this).toggleClass('active');
      $('.storiaPage__fullTxt').slideToggle(250);

       var x = document.getElementById("trig");
        if (x.innerHTML === "Vedi tutto") {
          x.innerHTML = "Vedi meno";
        } else {
          x.innerHTML = "Vedi tutto";
        }
    });
  },
  popupInit: function(){
    $('#down-sheet, #down-brochure, .brPop__closeBtn, .brPop__close').on('click', function(){
      $('.brPop').fadeToggle(250);
    })
  },
  filterExpand: function(){
    var fullH = $('.catsEl').outerHeight(),
        trig = document.getElementById("catTrig");

    $('.catsEl').css('height', '145px');

    $('#catTrig').on('click', function(){
      if (trig.innerHTML === "Più categorie") {
        trig.innerHTML = "Meno categorie";
      } else {
        trig.innerHTML = "Più categorie";
      }

      $('.catsEl').animate({
        height : $('.catsEl').height() == fullH ? 145 : fullH
      }, 250 );
    })
  },
  setLeftValue: function ($obj, $oth, $thumb, $range, $input) {
    var _this = $obj[0],
      min = parseInt(_this.min),
      max = parseInt(_this.max),
      currVal = _this.value;

      _this.value = Math.min(parseInt(_this.value), parseInt($oth[0].value) - 1);

      var percent = ((_this.value - min) / (max - min)) * 100;

      $thumb[0].style.left = percent + "%";
      $range[0].style.left = percent + "%";

      $input.val(currVal);
  },
  setRightValue: function ($obj, $oth, $thumb, $range, $input) {
    var _this = $obj[0],
        min = parseInt(_this.min),
        max = parseInt(_this.max),
        currVal = _this.value;


        _this.value = Math.max(parseInt(_this.value), parseInt($oth[0].value) + 1);

        var percent = ((_this.value - min) / (max - min)) * 100;

        $thumb[0].style.right = (100 - percent) + "%";
        $range[0].style.right = (100 - percent) + "%";

        $input.val(currVal);
  },
  rangeVal: function(){
    $('.filtBlock__slider').each(function(){
      var inputLeft = $(this).find('.input-left'),
          inputRight = $(this).find('.input-right'),
          valMin = $(this).find('.slider__val--min'),
          valMax = $(this).find('.slider__val--max'),
          thumbLeft = $(this).find('.thumb.left'),
          thumbRight = $(this).find('.thumb.right'),
          range = $(this).find('.range');

      siteGlobal.setLeftValue($(inputLeft), $(inputRight), $(thumbLeft), $(range), $(valMin));
      siteGlobal.setRightValue($(inputRight), $(inputLeft), $(thumbRight), $(range), $(valMax));

      $(inputLeft).on('input', function(){
        siteGlobal.setLeftValue($(inputLeft), $(inputRight), $(thumbLeft), $(range), $(valMin));
      });
      $(inputRight).on('input', function() {
        siteGlobal.setRightValue($(inputRight), $(inputLeft), $(thumbRight), $(range), $(valMax));
      });

    })
    
    $('.slider__val--min').on('keyup', function(){
      var currVal = $(this),
          inputRight = $(this).parent().siblings().find('.input-right'),
          valMin = parseInt($(this)[0].min),
          valMax = parseInt($(this)[0].max),
          thumbLeft = $(this).parent().siblings().find('.thumb.left'),
          range = $(this).parent().siblings().find('.range'),
          v = parseInt($(this)[0].value);

      if (currVal.val() >= valMin && currVal.val() <= valMax) {
        siteGlobal.setLeftValue(currVal, $(inputRight), $(thumbLeft), $(range), $(currVal));
        $(this).parent().siblings().find('.input-left').val(currVal.val());
      }
    })

    $('.slider__val--max').on('keyup', function(){
      var currVal = $(this),
          inputRight = $(this).parent().siblings().find('.input-left'),
          valMin = $(this)[0].min,
          valMax = $(this)[0].max,
          thumbRight = $(this).parent().siblings().find('.thumb.right'),
          range = $(this).parent().siblings().find('.range'),
          v = parseInt($(this)[0].value);

      if (currVal.val() >= valMin && currVal.val() <= valMax ) {
        siteGlobal.setRightValue(currVal, $(inputRight), $(thumbRight), $(range), $(currVal));
        $(this).parent().siblings().find('.input-right').val(currVal.val());
      }
    })

    /* Ripristino i filtri resettati sul click del bottone */
    $('#reset, [type="radio"]').on('click', function(){
      siteGlobal.resetFilters();
    })
  },
  resetFilters: function(){
    $('.filtBlock__slider').each(function(){
      var inputLeft = $(this).find('.input-left'),
          inputRight = $(this).find('.input-right'),
          valMin = $(this).find('.slider__val--min'),
          valMax = $(this).find('.slider__val--max'),
          thumbLeft = $(this).find('.thumb.left'),
          thumbRight = $(this).find('.thumb.right'),
          range = $(this).find('.range');

      function resetLeftValue() {
        var _this = $(inputLeft)[0],
          min = parseInt(_this.min),
          max = parseInt(_this.max),
          currVal = _this.min;
          

          _this.value = Math.min(parseInt(_this.value), parseInt($(inputRight)[0].value) - 1);

          var percent = ((_this.min - min) / (max - min)) * 100;

          $(thumbLeft)[0].style.left = percent + "%";
          $(range)[0].style.left = percent + "%";

          $(valMin).text(currVal);
      }
      resetLeftValue();

      function resetRightValue() {
        var _this = $(inputRight)[0],
          min = parseInt(_this.min),
          max = parseInt(_this.max),
          currVal = _this.max;


          _this.value = Math.max(parseInt(_this.value), parseInt($(inputLeft)[0].value) + 1);

          var percent = ((_this.max - min) / (max - min)) * 100;

          $(thumbRight)[0].style.right = (100 - percent) + "%";
          $(range)[0].style.right = (100 - percent) + "%";

          $(valMax).text(currVal);
      }
      resetRightValue();
    })
  },
  mobileFiltersInit: function(){
    $('#filt-mob-trig').on('click', function(){
      $('.trFilt__filWrapper').fadeIn(200);
    })
    $('#fil-close, .trFilt__recap').on('click', function(){
      $('.trFilt__filWrapper').fadeOut(200);
    })
  },
  rebuildAllEvents: function(){
    this.initLoad();
  },
};

global.siteGlobal = siteGlobal;


(function () {
  siteGlobal.initReady();
  
  $(window).on('load', function() {
    $('#preloader').addClass('loaded');
    siteGlobal.initLoad();
  });

  $(window).on('scroll', function(){
    siteGlobal.initScroll();
  })

  $(window).on('resize', function(){
    siteGlobal.resize();
  })
}());